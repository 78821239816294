import { IResponse } from "@/models/IClient";
import { IGroup, IGroupList } from "@/models/IGroup";
import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import { ISendGroupMessage } from "@/models/ISendGroupMessage";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;

class GroupService {
  group!: IGroup;
  lstGroup: IGroup[] = [];
  responsedata: any;

  saveGroup(model: IGroup): Promise<IResponse> {

    model.userId = userId
    model.clientId = clientId

    this.responsedata = axios
      .post<IResponse>(API_URL + "Group/savegroups", model, {
        headers: auhtHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
  sendGroupMessage(model: ISendGroupMessage): Promise<IResponse> {
    this.responsedata = axios
      .post<IResponse>(API_URL + "Group/sendsmstogroup", model, {
        headers: auhtHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
  getListofGroups(
    pageIndex: number,
    search: string = ""
  ): Result {
    var URL =
      API_URL + "Group/getgroupbyclientId/" + clientId + "/" + pageIndex;

    if (search.length > 0) {
      URL =
        API_URL +
        "Group/getgroupbyclientId/" +
        clientId +
        "/" +
        pageIndex +
        "?search=" +
        search;
    }

    this.responsedata = axios
      .get<IPageResponse>(URL, { headers: auhtHeader() })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getGroupByGroupId(groupId: number): Promise<IGroup> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL +
          "Group/GetGroupById?groupId=" +
          groupId +
          "&clientId="+clientId,
        { headers: auhtHeader() }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getGroupByClientId(): Promise<IGroup[]> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL +
          "Group/getgrouplistbyclientid?clientId=" +
          clientId,
        { headers: auhtHeader() }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getGroupMemberbyGroupid(groupId:Number): Promise<any> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL +
          "Group/getuserbygroupid?groupId=" +
          groupId,
        { headers: auhtHeader() }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getGroupByBranchId(branchId:Number): Promise<IGroup[]> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL +
          "Group/getgrouplistbybranchId?branchId=" +
          branchId,
        { headers: auhtHeader() }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
}

export default new GroupService();
