import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { IBranch } from "@/models/IBranch";
import BranchService from "@/services/BranchService";
import { IGroup, IGroupList } from "@/models/IGroup";
import Multiselect from "vue-multiselect";
import GroupService from "@/services/GroupService";
import ClientUserService from "@/services/ClientUserService";
import User from "../auth.module";
import { ISendGroupMessage } from "@/models/ISendGroupMessage";

@Options({
  components: {
    AdminLayout,
    Multiselect,
  },
})
export default class GroupMessages extends Vue {
  active = false;
  loading = false;
  totalItems = 0;
  branches: IBranch[] = [];
  branch: any = {};
  groups: IGroup[] = [];
  selectedBranch: any = {};
  group: any = {};
  isOpen!: false;
  emailTemplates = [];
  emailTemplate!: {};
  users: any = [];
  value: any = [];
  totalcharacter = 0;
  message = "";
  branchId = 0;
  groupId = 0;
  sendGroupMessageRequest: ISendGroupMessage = {
    groupId: 0,
    branchId: 0,
    users: [],
    message: "",
  };

  async created() {
    this.branches = await BranchService.getAllBranch();
  }

  charCount() {
    this.totalcharacter = this.message.length;
  }

  async onBranchChange(data: any) {
    if (data != undefined) {
      this.groups = await GroupService.getGroupByBranchId(data.branchId);
      var result = await ClientUserService.getClientUserByBranch(data.branchId);
      this.users = result;
      this.branchId = data.branchId;
    }
  }

  async onGroupChange(data: any) {
    if (data != undefined) {
      let groupMember: any = GroupService.getGroupMemberbyGroupid(
        data.groupId
      ).then((data) => {
        console.log(data);
        this.groupId = data.groupId;
        for (var i = 0; i < data.length; i++) {
          let tag = {
            userFullName: data[i].userFullName,
            value: data[i].mobileNo,
          };
          this.value.push(tag);
          this.users.push(tag)
          // this.value.push({clientUserId:groupMember[i].userId, userFullName: groupMember[i].userFullName})
        }
        console.log("this is tesat " + JSON.stringify(this.value));
      });
    }
  }

  addTag(newTag) {
    console.log(newTag)
    const tag = {
      userName: newTag,
      mobileNo: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };

    this.value.push(tag);
    this.users.push(tag)
  }

  onSubmit() {
    this.sendGroupMessageRequest.branchId = this.branchId;
    this.sendGroupMessageRequest.groupId = this.groupId;
    this.sendGroupMessageRequest.message = this.message;

    for (var i = 0; i < this.value.length; i++) {
      let user = {
        userName: this.value[i].userFullName,
        mobileNo: this.value[i].value,
      };
      this.sendGroupMessageRequest.users.push(user);
    }

    console.log(this.sendGroupMessageRequest);

    GroupService.sendGroupMessage(this.sendGroupMessageRequest).then(
      (data) => {
        this.loading = false;
        (this as any).$vs.notify({
          title: "SMS Message",
          text: data.message,
          color: "success",
          icon: "check_circle",
        });
        (this as any).$Progress.finish();
        this.sendGroupMessageRequest = {
          groupId: 0,
          branchId: 0,
          users: [],
          message: "",
        };
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "SMS Message",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
